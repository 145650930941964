<template>

    <div class="main-wrapper">
        <NavbarDashboard></NavbarDashboard>
        <SidebarDashboard></SidebarDashboard>
        <div class="main-content">
            <section class="section">
                <div class="d-flex mt-3 ml-3  mb-2 align-items-center">
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <router-link to="/data-client/detail" class="nav-link active">Akun Perkiraan</router-link>
                        </li>
                    </ul>
                </div>
                <div class="card">
                    <div class="row mb-5">
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <router-link to="/data-jurnal-perkiraan/detail" class="nav-link ">Detail</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/data-jurnal-perkiraan/history" class="nav-link active">History
                                </router-link>
                            </li>
                        </ul>
                    </div>




                    <div class="row">
                        <div class="col-6">
                           <div class="form-group">
                                <label for="inputAddress2">Tanggal :</label>
                                <input type="text" class="form-control input-none" id="inputAddress2" value="17-12-2019" readonly
                                    placeholder="Masuakan Nomor Telepon">

                                      <div class="form-group edit-vendor d-none">
                                    <Datepicker :autoApply="true"  v-model="date"></Datepicker>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="inputAddress2">Tipe Transaksi : </label>
                                <input type="text" class="form-control" id="inputAddress2" value="Pembelian Monitor" readonly>
                            </div>
                           
                        </div>

                        <div class="col-6">
                           

                            <div class="form-group">
                                <label for="inputAddress2">Tipe</label>
                                <input type="email" class="form-control input-none" id="inputAddress2" readonly
                                    placeholder="Masukkan Email" value="Kas & Bank">

                                <div class="form-group edit-vendor d-none">
                                    <Select2 v-model="AccountType" :options="OptionAccountType" placeholder="Tipe"
                                        :settings="{ settingOption: value, settingOption: value }"
                                        @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
                                </div>

                            </div>
                            <div class="form-group">
                                <label for="inputAddress2">Status :</label>
                                <input type="email" class="form-control input-none" id="inputAddress2" readonly
                                    placeholder="Masukkan Email" value="Aktif">

                                <div class="form-group edit-vendor d-none">
                                    <Select2 v-model="Satatus" :options="statusCompany" placeholder="Status"
                                        :settings="{ settingOption: value, settingOption: value }"
                                        @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="text-right groupBtn-detail">
                        <router-link to="/data-jurnal-perkiraan/history" class="btn btn-back ">Kembali</router-link>
                        <button class="btn btn-edit" v-on:click="clickEdit">Edit</button>
                    </div>


                    <div class="text-right d-none groupBtn-save-edit">
                        <button class="btn btn-cancel" v-on:click="clickCancel">Batal</button>
                        <button class="btn btn-save">Simpan</button>
                    </div>

                </div>
            </section>
        </div>
    </div>
</template>





<script>
    import SidebarDashboard from '../../../components/Sidebar.vue'
    import NavbarDashboard from '../../../components/Navbar.vue'
    import Select2 from 'vue3-select2-component';
    import Datepicker from 'vue3-date-time-picker';



    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"

    import $ from 'jquery'

    // import TableLite from 'vue3-table-lite'
    export default {
        name: 'dataDepartement',
        components: {
            // FooterDashboard,
            SidebarDashboard,
            NavbarDashboard,
            Select2,
            Datepicker
            // TableLite
        },

        data() {
            return {
                myValue: 'aaaa',
                statusCompany: ['Aktif', 'Tidak Aktif'], // or [{id: key, text: value}, {id: key, text: value}]
                optionPeriode: ['1 Bulan', '2 Bulan'],
                date: null,
                date2: null,
                OptionAccountType : ['Kas', 'Kas & Bank', 'BanK'],

                placeholder: [{
                    text: 'kecamatan'
                }]
            }
        },

        mounted() {
            $('#tableDepartment').DataTable({
                info: false,
            });
        },
        methods: {
            myChangeEvent(val) {
                console.log(val);
            },
            mySelectEvent({
                id,
                text
            }) {
                console.log({
                    id,
                    text
                })
            },

            clickEdit() {
                $('.groupBtn-save-edit').removeClass("d-none")
                $('.groupBtn-detail').addClass("d-none")
                $('input').removeAttr('readonly')
                $('textarea').removeAttr('readonly')
                $('.edit-vendor ').removeClass("d-none")
                $('.input-none').addClass("d-none")
            },

            clickCancel() {
                $('input').attr('readonly')
                $('.groupBtn-detail').removeClass("d-none")
                $('.groupBtn-save-edit ').addClass("d-none")
                $('input').attr('readonly', true)
                $('.edit-vendor ').addClass("d-none")
                $('.input-none').removeClass("d-none")

            }
        },


    }
</script>


<style scoped src="../../../assets/css/style.css">
</style>


<style scoped src="../../../assets/css/custom.css">
</style>



<style scoped>
    .btn-save {
        background-color: #40DDD4;
        color: #fff;
        width: 90px;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 0px;
    }

    .card .card-title {
        font-size: 18px;
        color: #000;
        font-weight: 500;
    }

    .card .nav-pills {
        margin: auto;
    }

    .card .nav .nav-item {
        margin-right: 30px;
    }

    .card {
        padding: 20px 50px;
        widows: 100%;
    }

      .card {
        border: 1px solid #EAEAEA;
        border-radius: 10px;
    }
</style>